.box_shadow{
    background-color: #dee9ff;
    border: 0.5px solid #1053D4;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 2px #1053D4;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.location_box{
    border-radius: 10px;
}