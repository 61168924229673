.Nav_Background{
    background-color: #DEE9FF;
    border-bottom: 0.5px solid black;
}
.Company_Name{
    color: #1364FF;
    font-weight: 600;
}
.Company_Name:hover{
    color: #1364FF;
}
.badges{
    font-weight: 600;
    font-size: small;
    height: fit-content;
    background-color: rgb(227, 152, 12);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    text-align: center;
    margin-top: -8px;
}
.Nav-Link{
    color: black;
    font-weight: 600;
}
.Nav-Link:hover{
    color:#D64779;
    font-size: larger;
    text-decoration: underline;
}