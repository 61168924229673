.design{
    background-color: #bebebe;
    color: black;
    border: 0.5px solid black;
    height: fit-content;
    width: fit-content;
    font-weight: 800;
}
.count{
    background-color: #bebebe;
    padding: 0.38rem;
    border-radius: 5px;
    height: min-content;
    border: 0.5px solid black;
}