.partners{
    font-size: medium;
}
.ourstory{
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 
    0 2px 5px 10px rgba(234, 234, 234, 0.5), 
    0 -2px 5px 10px rgba(234, 234, 234, 0.5),  
    2px 0 5px 10px rgba(234, 234, 234, 0.5),   
    -2px 0 5px 10px rgba(234, 234, 234, 0.5); 
}