.below_nav{
    background-color: #DEE9FF;
    height: fit-content;
}
.Body_Box{
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.Body_Box h6{
    color: #D64779;
    margin-bottom: 3rem;
}
.Body_Box h1{
    font-weight: 600;
    margin-bottom: 4rem;
}
.Body_Box p{
    margin-bottom: 5rem;
    font-size: 13.5px;
    font-weight: 500;
    color: #7A7A7A;
}
.Body_Box h4{
    color: #D64779;
}
.Body_Box h5{
    font-size: small;
}
.below_body{
    margin-top: -1.5rem;
}
.form_div {
   align-self: center;
   margin-left: -2.8rem;
}
.Buy_Now_Button{
    text-align: center;
    text-decoration: none;
    background-color: #1053D4;
    padding: 0.4rem;
    margin-top: -7px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.products-box{
    background-color: #fff;
    box-shadow: 5px 5px 5px 5px #c1c1c1;
    padding: 0;
    border-radius: 6px;
    height: fit-content;
}
.products-box h6 {
    padding-left: 9px;
    font-weight: 400;
}
.products-box p{
    padding-left: 9px;
    font-size: small;
    font-weight: 600;
}
.no-outline-input {
    border: none;
    box-shadow: none;
    outline: none;
    border-bottom: 0.5px solid black;
    border-radius: 0px;
}  
.no-outline-input:focus {
    box-shadow: none;
    outline: none;
}
.contact-box{
    background-color: #1053D4;
    border-radius: 10px;
    color: #fff;
    padding-top: 2px;
    
}
.contact-box p{
    font-size: small;
}
.form-control::-webkit-inner-spin-button,
.form-control::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
