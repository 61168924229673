.main_footer{
background-color: #1364FF;
height: 500px;
}
.foot{
    color: #fff;
    font-size: small;
}
.company ul{
    list-style-type: none;
    padding: 0;
}
.company{
    color:#fff;
}
.Links{
    text-decoration: none;
    color: #fff;
}
.resources{
    color: #fff;
}
.resources ul{
    list-style-type: none;
    padding: 0;
}