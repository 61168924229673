.img-box {
    background-color: #fff;
    box-shadow: 5px 5px 5px #c4c4c4;
    padding: 0.5rem;
    border-radius: 10px;
}

.down-img-box {
    background-color: #fff;
    padding: 0.5rem;
    box-shadow: 5px 5px 5px #c4c4c4;
    height: 10rem;
}

.size-box {
    border: 0.5px solid #494949;
    width: fit-content;
    padding: 5px;
    border-radius: 15px;
}

.size-box:active {
    background-color: #add8e6;
    font-weight: 500;
    border: 1px solid #000000;
}

.price-box {
    border: 0.5px solid #494949;
    width: fit-content;
    padding: 5px;
    border-radius: 15px;
    margin-left: 0.5rem;
}

/* {
product.Price.split(',').map((price, Index) =>
<div className="price-box"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
<path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
</svg> {price} /-</div>
 )
} */